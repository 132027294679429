<template>
  <ul class="list">
    <li v-for="item of list" :key="item.id">
      <router-link :to="{path, query: {id: item.id}}">
        <span><i></i>{{item.subject}}</span>
        <div>
          {{formartTime(item.createTime)}}
        </div>
      </router-link>
    </li>
  </ul>
  <div class="pagebox">
    <el-pagination
      layout="prev, pager, next"
      :page-size="12"
      :current-page="pageNo"
      @current-change="pageChange"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { ElPagination } from 'element3'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'List',
  components: {
    ElPagination
  },
  props: {
    getList: {
      type: Function,
      required: true
    },
    path: String
  },
  setup (props) {
    const list = ref([])
    const pageNo = ref(1)
    const total = ref(0)

    const getListData = () => {
      props.getList({ pageNo: pageNo.value, pageSize: 12 }).then(res => {
        total.value = res.result.total
        list.value = res.result.records
      })
    }
    onMounted(() => {
      getListData()
    })

    const pageChange = (page) => {
      pageNo.value = page
      getListData()
    }

    const formartTime = (val) => {
      return dayjs(val).format('YYYY/MM/DD')
    }

    return { list, pageNo, total, pageChange, formartTime }
  }
})
</script>

<style>
.el-pager li{
  min-width: 28px;
}
.el-pager li.active{
  background: #07C160;
  border-radius: 50%;
  color: #ffffff;
  padding:0;
}
</style>
<style lang="scss" scoped>

.list{
  margin-bottom: 50px;
  border-bottom: 1px solid #DDDDDD;

  li{
    width: 1152px;
    margin: 0 auto;
    border-bottom: 1px dotted #DDDDDD;
    line-height: 55px;
    &:last-child{
      border-bottom: 0;
    }
  }

  a{
    display: flex;justify-content: space-between; align-items: center;padding: 0 8px;
    &:hover > div, &:hover > span{
      color:#07C160;
    }
  }
  span{
    display: block;
    align-items: center;
    width: 900px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }
  i{
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 8px;
    background: #333333;
  }
}
</style>
