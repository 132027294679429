<template>
  <div class="w">
    <div class="crumbs">
      <router-link to="/">首页</router-link>
      <span>/</span>
      技术规范
    </div>
    <div class="list">
      <List :getList="getList" path="/standard"></List>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import List from '../components/List'
import { standardList } from '../api'

export default defineComponent({
  name: 'StandardList',
  components: {
    List
  },
  setup () {
    const getList = (data) => {
      return standardList(data)
    }
    return { getList }
  }
})
</script>

<style lang="scss" scoped>
.list{
  padding: 0 20px;
}
</style>
